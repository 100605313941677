import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import "./StaticPage.css";

function TermsCondition() {
  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
          Terms & Conditions | Masala of India | Indian Restaurant in Seattle
        </title>
        <meta
          name="description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta
          name="keywords"
          content="Terms & Conditions, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Terms & Conditions  | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          property="og:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/terms-condition"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Terms & Conditions  | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          name="twitter:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta name="twitter:site" content="masala of india" />
        <link
          rel="canonical"
          href="https://www.masalaofindia.com/terms-condition"></link>
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Terms & Conditions</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Terms & Conditions</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid static-page">
        <div className="row">
          <div className="col-12">
            <div className="section-header mb-1">
              <h3 className="text-center">Terms & Conditions</h3>
            </div>
            <p>
              Welcome to Masala of India! By accessing or using our online food
              ordering platform, you agree to comply with the following Terms
              and Conditions. These terms outline your rights, obligations, and
              limitations when using our services. Please read them carefully.
            </p>
            <ul>
              <b>1. Acceptance of Terms</b>
              <li>
                By creating an account or placing an order, you accept these
                Terms and Conditions and agree to be bound by them. If you do
                not agree, please refrain from using our platform.
              </li>
              <b>2. Account Responsibilities</b>
              <li>
                When creating an account, you agree to provide accurate
                information and keep your account details secure. You are
                responsible for all activities under your account, and we are
                not liable for any unauthorized access resulting from your
                failure to maintain account security.
              </li>
              <b>3. Order Placement and Payment</b>
              <li>
                Once you place an order, you authorize us to process your
                payment. Payment options may include credit/debit cards, online
                payment platforms, or other methods displayed at checkout. All
                payments must be confirmed before the order is fulfilled.
                Pricing and availability may change without notice.
              </li>
              <b>4. Cancellations and Refunds</b>
              <li>
                Orders cannot be canceled after they are confirmed. Refunds are
                only issued for situations where the item is not available, or
                if an order has not been processed due to technical issues. For
                order-related concerns, please contact our customer support.
              </li>
              <b>5. Delivery Policy</b>
              <li>
                Masala of India partners with third-party delivery services to
                bring your order to you. Delivery timelines provided are
                estimates and may vary due to unforeseen circumstances. We are
                not liable for delays caused by factors outside our control,
                including traffic, weather, or third-party logistics.
              </li>
              <b>6. Limitation of Liability</b>
              <li>
                We are committed to providing a high-quality service; however,
                we are not responsible for errors, delays, or omissions related
                to order fulfillment. Masala of India is not liable for damages
                arising from your use of the platform, including any indirect,
                incidental, or consequential damages.
              </li>
              <b>7. Intellectual Property</b>
              <li>
                All content on our website, including text, images, graphics,
                and trademarks, is the property of Masala of India and protected
                under U.S. copyright and trademark laws. Unauthorized use,
                reproduction, or distribution is prohibited.
              </li>
              <b>8. Modifications to Terms</b>
              <li>
                Masala of India reserves the right to update or modify these
                Terms and Conditions at any time. Changes will be posted on our
                website, and your continued use of our platform signifies your
                acceptance of the updated terms.
              </li>
              <b>9. Governing Law</b>
              <li>
                These Terms and Conditions are governed by the laws of the
                United States. Any disputes arising from the use of our platform
                shall be subject to the exclusive jurisdiction of U.S. courts.
              </li>
            </ul>
            <p>
              Thank you for choosing Masala of India. For any questions about
              these Terms and Conditions, please contact our customer support
              team. Enjoy your food ordering experience with us!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default TermsCondition;
