import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment/moment";
import Notiflix from "notiflix";
import { Helmet } from "react-helmet";
import { FaFilePdf } from "react-icons/fa";
import * as Yup from "yup";
import cateringmenu from "../../../assets/pdf/cateringmenu-1.pdf";
import PostApiCall from "../../../helper/PostAPI";
import "../assets/css/style.css";
import LeftIcon from "../assets/images/catering/booking-left.png";
import "../Catering/Catering.css";
import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";

export default function Catering() {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      people: "",
      date: "",
      occasion: "",
      // servings: "",
      budget: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required*"),
      phone: Yup.string()
        .matches(/^\d+$/, "Phone number is invalid")
        .required("This field is required*"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("This field is required*"),
      people: Yup.string()
        .matches(/^\d+$/, "Amount of people is invalid")
        .required("This field is required*"),
      date: Yup.date().required("This field is required*"),
      occasion: Yup.string().required("This field is required*"),
      // servings: Yup.string()
      //   .matches(/^\d+$/, "Number of servings is invalid")
      //   .required("This field is required*"),
      budget: Yup.string()
        .matches(/^\d+$/, "Budget is invalid")
        .required("This field is required*"),
      // comments: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const {
        name,
        phone,
        email,
        people,
        date,
        occasion,
        // servings,
        budget,
        comments,
      } = values;
      sendMail(
        name,
        phone,
        email,
        people,
        date,
        occasion,
        // servings,
        budget,
        comments
      );
      formik.resetForm();
    },
  });

  // send mail to the admin
  function sendMail(
    name,
    phone,
    email,
    people,
    date,
    occasion,
    // servings,
    budget,
    comments
  ) {
    // const formattedDate = moment(date).format("YYYY-MM-DD hh:mm a");
    // const formattedDate = moment(date).format("MMMM DD, YYYY hh:mm a"); // formate May 22, 2024 05:03 pm
    Notiflix.Loading.Dots("Please Wait...");
    PostApiCall.postRequest(
      {
        id: null,
        type: "Catering",
        contactperson: name,
        contactnumber: phone,
        email: email,
        peoplecount: people,
        pickupdate: date,
        occasion: occasion,
        // servings: servings,
        personbudget: budget,
        message: comments,
      },
      "cateringenquiries"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          // SendAndroidPush();
          Notiflix.Notify.Success("Mail sent");
        } else {
          Notiflix.Notify.Failure("Failed");
        }
      })
    );
  }
  const SendAndroidPush = (message) => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        orderNumber: "message.ordernumber",
        customerName: "",
        amount: 0.0,
        location: "",
        orderTime: "message.ordertime",
        title: "Hi, you have a new order",
        body: "`${logindetails[0]?.fld_name} placed an order,  Order Number: ${message.ordernumber}`",
      },
      "SendAndroidPush"
    ).then((result) =>
      result?.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
        } else {
          Notiflix.Notify.Failure("Oops!", "Something went wrong", "OK");
        }
      })
    );
  };

  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
          Best Catering Services in Seattle - Masala of India Cuisine
        </title>
        <meta
          name="description"
          content="Masala Of India has expertise in off-campus catering services and offers the best catering services in Seattle. Our crew consists of talented, highly proficient ..."
        />
        <meta
          name="keywords"
          content="Catering , Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Best Catering Services in Seattle - Masala of India Cuisine"
        />
        <meta
          property="og:description"
          content="Masala Of India has expertise in off-campus catering services and offers the best catering services in Seattle. Our crew consists of talented, highly proficient ..."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/catering"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Best Catering Services in Seattle - Masala of India Cuisine"
        />
        <meta
          name="twitter:description"
          content="Masala Of India has expertise in off-campus catering services and offers the best catering services in Seattle. Our crew consists of talented, highly proficient ..."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta name="twitter:site" content="masala of india" />
        <link rel="canonical" href="https://www.masalaofindia.com/catering"></link>
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Catering</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Catering</li>
                  </ol>
                </nav>
                <div>
                  <button
                    type="submit"
                    className="custom-button catering-menu mt-4 me-lg-4"
                  >
                    <a className="text-white" href={cateringmenu} target="blank">
                      Download Catering Menu <FaFilePdf className="ms-2" />
                    </a>
                  </button>
                  <button
                    type="submit"
                    className="custom-button catering-menu mt-4"
                  >
                    <a className="text-white" href='https://www.toasttab.com/invoice/lead?rx=09f6d387-046f-4c25-a83b-a558faf97bbd&ot=34d6f371-9895-4e32-95f0-45d042f371d1' target="blank">
                      Book Your Catering
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="book-table-section book-reservation-section my-4 my-lg-5">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-1">
                  <h2>Serve happiness to your guests</h2>
                  <h3>Make your event a tastier affair</h3>
                  <p>
                    Masala Of India has expertise in off-campus catering
                    services and offers the best catering services in Seattle.
                    Our crew consists of talented, highly proficient, and
                    well-trained staff members who are delighted to serve you.
                    So, whether you are seeking catering services for an
                    intimate party, a corporate conference, a wedding, or a
                    competition, Masala of India is the catering company to
                    contact. Excelling in catering services for the last 20
                    years we understand the needs of your taste buds and provide
                    you the option of optimizing the spiciness in your food
                    making it more crowd-friendly.
                  </p>
                  <p className="pb-1 mb-0">
                    Send us your contact and requirement, our sales team will
                    get in touch with you shortly.
                  </p>
                  {/* <p className="pb-1 mb-0 fs-5">
                    <strong>Contact Us <br/> Sanjay Sharma</strong>
                  </p>
                  <p className="my-0 py-0">
                    <a
                      href="tel:+206369-6165"
                      className="text-dark fs-5 fw-bold"
                    >
                      <i>+1(206) 369-6165</i>
                    </a>
                  </p> */}
                </div>
              </div>

              {/*  ***** Catering form **** */}
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="book-table-box">
                    <div className="title-area mt-0 mb-0">
                      <img src={LeftIcon} alt="masala-of-india" />
                      <h2 className="mx-3">Catering</h2>
                      <img src={LeftIcon} alt="masala-of-india" />
                    </div>
                    <h3 className="text-center mb-4">Write to us</h3>
                    <div className="book-form">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="col-12 col-md-4 mb-4">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Name *"
                              aria-label=".form-control-lg example"
                              name="name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4 mb-4">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Phone Number *"
                              aria-label=".form-control-lg example"
                              name="phone"
                              value={formik.values.phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-4 mb-4">
                            <input
                              className="form-control form-control-lg"
                              type="email"
                              placeholder="Email Address *"
                              aria-label=".form-control-lg example"
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-12 col-md-6 mb-4">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Number of People *"
                              aria-label=".form-control-lg example"
                              name="people"
                              value={formik.values.people}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.people && formik.errors.people ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.people}
                              </div>
                            ) : null}
                          </div>

                          {/* ******* Show formate in yyyy-mm-dd  */}
                          {/* <div className="col-12 col-md-6 mb-4 datepicker-size">
                            <DatePicker
                              placeholder="Desired Date and Time *"
                              showTime={{ format: "hh:mm A" }}
                              className="form-control form-control-lg catering-label"
                              value={
                                formik.values.date
                                  ? moment(formik.values.date)
                                  : null
                              }
                              onChange={(date, dateString) =>
                                formik.setFieldValue("date", dateString)
                              }
                              onBlur={formik.handleBlur("date")}
                              disabledDate={(current) =>
                                current && current < moment().startOf("day")
                              } // To disable past dates
                            />
                            {formik.touched.date && formik.errors.date ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.date}
                              </div>
                            ) : null}
                          </div> */}

                          {/* **** show formate in mm-dd-yyyy */}
                          <div className="col-12 col-md-6 mb-4 datepicker-size">
                            <DatePicker
                              placeholder="Desired Date and Time *"
                              showTime={{ format: "hh:mm A" }}
                              format="MM-DD-YYYY HH:mm:ss"// Add this line to set the desired date format
                              className="form-control form-control-lg catering-label"
                              value={formik.values.date
                                ? dayjs(formik.values.date)
                                : null}
                              onChange={(date, dateString) =>
                                formik.setFieldValue("date", dateString)
                              }
                              // onBlur={formik.handleBlur("date")}
                              disabledDate={(current) =>
                                current && current < moment().startOf("day")
                              } // To disable past dates
                            />
                            {formik.touched.date && formik.errors.date ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.date}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-6 mb-4 col-12">
                            <select
                              placeholder="Select Occasion *"
                              className="form-select form-control form-control-lg"
                              id="inputGroupSelect01"
                              value={formik.values.occasion}
                              name="occasion"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option selected> Select Occasion *</option>
                              <option value="Birthday">Birthday</option>
                              <option value="Graduation">Graduation</option>
                              <option value="Holiday Party">
                                Holiday Party
                              </option>
                              <option value="Baby Shower">Baby Shower</option>
                              <option value="Bridal Shower">
                                Bridal Shower
                              </option>
                              <option value="Rehearsal Dinner">
                                Rehearsal Dinner
                              </option>
                              <option value="Retirement">Retirement</option>
                              <option value="Bachelor/Bachelorette">
                                Bachelor/Bachelorette
                              </option>
                            </select>
                            {formik.touched.occasion &&
                              formik.errors.occasion ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.occasion}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-12 col-md-6 mb-4">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Budget per person *"
                              aria-label=".form-control-lg example"
                              name="budget"
                              value={formik.values.budget}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.budget && formik.errors.budget ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.budget}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 mb-4">
                            <textarea
                              className="form-control form-control-lg catering-textarea"
                              id="validationTextarea"
                              placeholder="Additional Comments"
                              name="comments"
                              value={formik.values.comments}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            ></textarea>
                          </div>

                          <div className="col-lg-12 text-center">
                            <button type="submit" className="custom-button">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ***** Service Sections ***** */}
      {/* <section className="book-table-section book-reservation-section my-4 my-lg-5">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-4 mt-4">
                  <h2>Our Services</h2>
                  <h3>Make your event a tastier affair</h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h5 className="fw-bold">Wedding Catering</h5>
              <p>
                A wedding is one of the most important and memorable events for
                a couple. It is not a casual event. An eye for detailing,
                perfect timing and clear communication are crucial for a
                wedding. A skilful wedding caterer knows the industry
                upside-down and can offer you the deliverables punctually while
                being cost-effective.
              </p>
              <p>
                We give our sincere efforts to make your wedding day the most
                memorable day with our elegant services. Our artistic staff has
                an eye for detailing, enabling them to craft the event the way
                you want it. Masala of India is well-renowned for its finest
                wedding catering in Seattle.
              </p>
            </div>
            <div className="col-12">
              <h5 className="fw-bold">Corporate Catering</h5>
              <p>
                Corporate Catering refers to serving corporate events such as
                meetings, conferences, exhibitions, etc. These events pose an
                opportunity of bringing your staff and clients together, thereby
                enhancing the network. Hence, it becomes crucial to hire
                professional caterers who can make adjustments as per the
                clients' needs. Masala Of India offers the best corporate
                catering services for your company. We embrace the privilege of
                helping you in your corporate confluences. Whether you are
                hosting a large scale conference with over thousands of guests,
                or an exhibition with over fifty people, we provide exceptional
                catering services at pocket-friendly prices.
              </p>
            </div>
            <div className="col-12">
              <h5 className="fw-bold">Social Events</h5>
              <p>
                Social Events are more closed and cozy events that require
                caterers' great attention to detail and effective communication.
                Due to limited attendants, you have the leverage of shaping up
                the menu as per your taste, preferences, cultural beliefs, and
                dietary needs. Masala Of India is well-known for catering
                services for social events like grand openings, birthday
                parties, farewell feasts, housewarming parties, etc. Our
                well-groomed, highly skilled staff devotes itself to ensuring
                that your event turns out to be the best in your social
                circle.We offer you the best catering lunch in Seattle.
              </p>
            </div>
            <div className="col-12">
              <h5 className="fw-bold">Concession Catering</h5>
              <p>
                This category covers main sports events, live concerts, seasonal
                competitions, and fan meetups where you have to cater to
                thousands of people. Since these events receive higher footfall
                than other events, the primary focus should be to offer the most
                popular food at the express speed rather than giving a vast
                variety of food items.
              </p>
              <p>
                Executing these events needs several skills and experience of
                public dealing to ensure that you offer the right menu choices
                for all the audiences. Besides that, it is crucial to increase
                the volume while delivering consistent quality. Masala Of India
                is renowned for offering the best catering services in volumes
                while maintaining consistent quality. Due to our sincere,
                professional crew today, the Masala Of India is synonymous with
                top-notch quality.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
}
